import React, { useState } from "react";
import Slider from "react-slick";
import HTMLReactParser from "html-react-parser";

import { Wrapper, Slide, BackButton, TipsWrapper } from "./Stories.styles";
import { Arrow } from "../icons";

const Stories = (props) => {
  //PROPS
  const {
    data: { slidess, image, mobileImage },
    color
  } = props;
  //PROPS

  //STATES
  const [active, setActive] = useState(null);
  //STATES

  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);

  function handleTouchStart(e) {
    setTouchStart(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e) {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  function handleTouchEnd(e) {
    if (!e) {
      if (touchStart - touchEnd > 150) {
        // do your stuff here for left swipe
        // console.log("qwe");
        setActive(1);
        //moveSliderRight();
      }

      if (touchStart - touchEnd < -150) {
        // do your stuff here for right swipe
        // console.log("qwe2");
        setActive(0);
        //moveSliderLeft();
      }
    } else if (e) {
      setActive(null);
    }
  }

  return (
    <Wrapper
      color={color}
      position={
        active === 0
          ? "translateX(100%)"
          : active === 1
          ? "translateX(-100%)"
          : null
      }
    >
      <div
        className="bg"
        onTouchStart={(touchStartEvent) => handleTouchStart(touchStartEvent)}
        onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent)}
        onTouchEnd={() => handleTouchEnd()}
      >
        <img
          alt={image?.altText}
          src={image?.localFile?.childImageSharp?.fluid?.src}
          srcSet={image?.localFile?.childImageSharp?.fluid?.srcSet}
        />
        <img
          className="mobile"
          alt={mobileImage?.altText}
          src={mobileImage?.localFile?.childImageSharp?.fluid?.src}
          srcSet={mobileImage?.localFile?.childImageSharp?.fluid?.srcSet}
        />
        <div className="container">
          <div className="bg-cta bg-cta--down">
            <button onClick={() => setActive(0)}>
              <span>{slidess[0].title}</span>
              <Arrow color={false} />
            </button>
          </div>
          <div className="bg-cta bg-cta--up">
            <button onClick={() => setActive(1)}>
              <span>{slidess[1].title}</span>
              <Arrow color={false} />
            </button>
          </div>
        </div>
      </div>
      {slidess.map((elem, index) => {
        let image = elem.image?.localFile?.childImageSharp?.fluid;
        let text = elem.text.split("<p>[tips]</p>");

        const Tips = () => {
          const settings = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            draggable: false,
            adaptiveHeight: false,
            slidesToShow: 1,
            fade: false,
            slidesToScroll: 1,
            swipeToSlide: false,
            autoplay: true,
            autoplaySpeed: 4000,
            swipe: true
          };
          return (
            <TipsWrapper color={color}>
              <Slider {...settings}>
                {elem.tips &&
                  elem.tips.map((elem, index) => {
                    return <div key={index}>{HTMLReactParser(elem.tip)}</div>;
                  })}
              </Slider>
            </TipsWrapper>
          );
        };

        return (
          <Slide
            color={color}
            key={index}
            data-index={index}
            position={index === 0 ? "left" : "right"}
            className={active === index ? "active" : ""}
          >
            <div
              className="slide-image"
              onTouchStart={(touchStartEvent) =>
                handleTouchStart(touchStartEvent)
              }
              onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent)}
              onTouchEnd={() => handleTouchEnd(index === 0 ? "left" : "right")}
            >
              <img
                src={image.src}
                srcSet={image.srcSet}
                alt={elem.image.altText}
              />
              <BackButton
                onClick={() => setActive(null)}
                position={index === 0 ? "left" : "right"}
              >
                <Arrow color={false} />
                <span>Voltar</span>
              </BackButton>
            </div>
            <div className="slide-text">
              <div className="textc">
                {elem.text &&
                  text.map((el, index) => {
                    if (index === 0 && text[1]) {
                      return (
                        <div key={index}>
                          {HTMLReactParser(el)}
                          <Tips />
                        </div>
                      );
                    } else {
                      return HTMLReactParser(el);
                    }
                  })}
              </div>
            </div>
          </Slide>
        );
      })}
    </Wrapper>
  );
};

export default Stories;
