import styled from "styled-components";
import { p } from "theme/typography";
import { breakpoints, toRem } from "utils/mixins";

export const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: ${(props) => (props.color ? props.color : "white")};
  .bg {
    position: relative;
    transition: 400ms;
    transform: ${(props) => props.position};
    img {
      left: 0;
      top: 0;
      width: 100vw;
      min-height: 100vh;
      max-height: 100vh;
      object-fit: cover;
      position: absolute;
      display: none;
      &.mobile {
        display: block;
      }
      @media (min-width: 1023px) {
        display: block;
        &.mobile {
          display: none;
        }
      }
    }
    .container {
      height: 100vh;
      .bg-cta {
        position: absolute;
        z-index: 2;
        width: 100%;
        button {
          width: 100%;
        }
        span {
          cursor: pointer;
          display: block;
          font-family: "Scribble Box";
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: 1.2px;
          text-align: center;
          text-transform: uppercase;
          color: white;
          @media (min-width: 474px) {
            font-size: 31px;
            line-height: 38px;
          }
        }
        svg {
          height: 38px;
          transform: rotate(90deg);
        }
        &--down {
          left: 0;
          top: 50%;
          transform: translateY(100%);
          @media (min-height: 620px) {
            transform: translateY(80%);
          }
          @media (min-width: 959px) {
            transform: translateY(25%);
          }
          @media (min-width: 1439px) {
            transform: translateY(40%);
          }
          @media (min-width: 1920px) {
            transform: translateY(100%);
          }
        }
        &--up {
          right: 0;
          bottom: 50%;
          transform: translateY(-100%);
          @media (min-height: 620px) {
            transform: translateY(-80%);
          }
          @media (min-width: 959px) {
            transform: translateY(-25%);
          }
          @media (min-width: 1439px) {
            transform: translateY(-40%);
          }
          @media (min-width: 1920px) {
            transform: translateY(-100%);
          }
          svg {
            transform: rotate(-90deg);
          }
        }
        @media (min-width: 620px) {
          width: 300px;
        }
      }
    }
  }
`;

export const Slide = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background-color: ${(props) => (props.color ? props.color : "white")};
  z-index: 41;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  transition: 400ms;
  transform: ${(props) =>
    props.position === "right" ? "translateX(100%)" : "translateX(-100%)"};
  * {
    outline: none !important;
  }
  &.active {
    transform: translateX(0);
  }
  .slide-image {
    position: relative;
    width: 100%;
    height: 60vh;
    order: 1;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .slide-text {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
    justify-content: ${(props) =>
      props.position === "right" ? "flex-start" : "flex-end"};
    order: 2;
    padding-bottom: 80px;
    .textc {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
    }
    p {
      color: black;
    }
    h2 {
      text-align: left;
      /* /H3 - Futura Bold 20 */

      margin: 0;
      margin-bottom: 8px;
      font-family: Futura PT;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.2px;

      /* orange */
      color: ${(props) => (props.color ? props.color : "#fa8c51")};
    }
  }
  @media (min-width: 1023px) {
    .slide-text {
      .textc {
        padding: ${(props) =>
          props.position === "right" ? "0 20px 0 150px" : "0 150px 0 20px"};
      }
    }
  }

  @media (min-width: 1023px) {
    flex-direction: row;
    height: 100vh;
    overflow-y: auto;
    .slide-image {
      height: auto;
      width: 50%;
      order: ${(props) => (props.position === "right" ? "1" : "2")};
    }
    .slide-text {
      width: 50%;
      padding-bottom: 0;
      padding: 10px;
      padding-top: 150px;
      order: ${(props) => (props.position === "right" ? "2" : "1")};
      .textc {
        max-width: ${toRem(700)};
        height: 100%;
        overflow-y: scroll;
        padding: ${(props) =>
          props.position === "right" ? "50px 20px 0 70px" : "50px 70px 0 20px"};

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar {
          width: 5px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #e7762c;
          border-radius: 10px;
        }
      }
    }
  }

  /* @media (min-width: 768px) and (orietation: landscape) {
    flex-direction: row;
    height: 100vh;
    overflow-y: auto;
    .slide-image {
      height: auto;
      width: 50%;
      order: ${(props) => (props.position === "right" ? "1" : "2")};
    }
    .slide-text {
      width: 50%;
      padding-bottom: 0;
      order: ${(props) => (props.position === "right" ? "2" : "1")};
      .textc {
        max-width: ${toRem(700)};
        padding: ${(props) =>
    props.position === "right" ? "0 20px 0 150px" : "0 150px 0 20px"};
      }
    }
  } */
`;

export const BackButton = styled.button`
  position: absolute;

  right: ${(props) => (props.position === "right" ? "unset" : "20px")};
  left: ${(props) => (props.position === "right" ? "20px" : "unset")};
  top: 55vh;
  display: flex;
  align-items: center;
  span {
    cursor: pointer;
    order: ${(props) => (props.position === "right" ? "2" : "1")};
    font-family: "Futura PT";
    font-weight: bold;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: white;
  }
  svg {
    order: ${(props) => (props.position === "right" ? "1" : "2")};
    width: 10px;
    transform: ${(props) =>
      props.position === "right" ? "rotate(90deg)" : "rotate(-90deg)"};
    margin-right: ${(props) => (props.position === "right" ? "20px" : "0")};
    margin-left: ${(props) => (props.position === "right" ? "0" : "20px")};
  }

  @media ${breakpoints.laptop} {
    /* left: unset;
    right: 100px; */
    top: 30%;

    right: ${(props) => (props.position === "right" ? "100px" : "20px")};
    left: ${(props) => (props.position === "right" ? " unset" : "unset")};
    transform: ${(props) =>
      props.position === "right" ? "" : "translateX(-40px)"};
  }
  @media (min-width: 1400px) {
    right: ${(props) => (props.position === "right" ? "" : "unset")};
    left: ${(props) => (props.position === "right" ? "" : "700px")};
    transform: ${(props) =>
      props.position === "right" ? "" : "translateX(-130%)"};
  }
`;

export const TipsWrapper = styled.div`
  ${p}
  font-weight: bold;
  color: black;
  padding: 20px;
  .slick-dots {
    margin-top: 10px;
    display: flex !important;
    li {
      margin-right: 10px;
      button {
        color: transparent;
        background-color: ${(props) => props.color};
        border-radius: 50%;
        width: 8px;
        height: 8px;
        transform: scale(0.66);
        opacity: 0.25;
      }
    }
    .slick-active {
      button {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
`;
